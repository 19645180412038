.moving-border-below {
  background-image: repeating-linear-gradient(to top, 
      transparent, transparent 2px, #008001 7px);
  background-size: 100% 200%;
  animation: moveBelow 3s linear infinite;
}

.moving-border-right {
  background-image: repeating-linear-gradient(to left, 
     transparent, transparent 2px, #008001 7px);
  background-size: 200% 100%;
  animation: moveRight 3s linear infinite;
}

.moving-border-left {
  background-image: repeating-linear-gradient(to right, 
     transparent, transparent 2px, #008001 7px);
  background-size: 200% 100%;
  animation: moveLeft 3s linear infinite;
}

.moving-border-above {
  background-image: repeating-linear-gradient(to bottom, 
     transparent, transparent 2px, #008001 7px);
  background-size: 100% 200%;
  animation: moveAbove 3s linear infinite;
}

.border {
  border: 2px dashed black;
  height: 70px;
}

.border-dark {
  border: 2px dashed #F2E34C; /* Yellow color for dark theme */
  height: 70px;
}

@keyframes moveBelow {
  from {
    background-position: bottom;
  }
  to {
    background-position: top;
  }
}

@keyframes moveRight {
  from {
    background-position: right;
  }
  to {
    background-position: left;
  }
}

@keyframes moveLeft {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

@keyframes moveAbove {
  from {
    background-position: top;
  }
  to {
    background-position: bottom;
  }
}
