.groupChild,
.groupItem {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  width: 824px;
  height: 780px;
}
.groupItem {
  top: 725px;
  left: 666px;
  border-radius: var(--br-5xs);
  background-color: var(--color-dodgerblue-100);
  width: 105px;
  height: 32px;
}
.save {
  position: absolute;
  top: 731px;
  left: 700px;
  font-size: var(--font-size-base);
  font-weight: 600;
  color: var(--color-white);
}
.groupInner {
  position: absolute;
  top: 725px;
  left: 540px;
  border-radius: var(--br-5xs);
  background-color: var(--color-dodgerblue-100);
  width: 105px;
  height: 32px;
}
.cancel,
.mon,
.tue {
  font-size: var(--font-size-base);
  font-weight: 600;
}
.cancel {
  position: absolute;
  top: 731px;
  left: 566px;
  color: var(--color-white);
}
.mon,
.tue {
  top: 11px;
  color: var(--color-darkslategray-200);
}
.mon {
  position: absolute;
  left: 104px;
}
.tue {
  left: 206px;
}
.thu,
.tue,
.wed {
  position: absolute;
}
.wed {
  top: 11px;
  left: 303px;
  font-size: var(--font-size-base);
  font-weight: 600;
  color: var(--color-darkslategray-200);
}
.thu {
  left: 398px;
}
.fri,
.sat,
.thu {
  top: 11px;
  font-size: var(--font-size-base);
  font-weight: 600;
  color: var(--color-darkslategray-200);
}
.fri {
  position: absolute;
  left: 510px;
}
.sat {
  left: 608px;
}
.div,
.sat,
.sun {
  position: absolute;
}
.sun {
  top: 11px;
  left: 706px;
  font-size: var(--font-size-base);
  font-weight: 600;
  color: var(--color-darkslategray-200);
}
.div {
  top: 44px;
  left: 11px;
  font-weight: 500;
}
.div1,
.div2,
.div3,
.div4,
.div5,
.div6,
.div7,
.div8 {
  position: absolute;
  top: 73px;
  left: 11px;
  font-weight: 500;
}
.div2,
.div3,
.div4,
.div5,
.div6,
.div7,
.div8 {
  top: 102px;
}
.div3,
.div4,
.div5,
.div6,
.div7,
.div8 {
  top: 131px;
}
.div4,
.div5,
.div6,
.div7,
.div8 {
  top: 160px;
}
.div5,
.div6,
.div7,
.div8 {
  top: 189px;
}
.div6,
.div7,
.div8 {
  top: 218px;
}
.div7,
.div8 {
  top: 247px;
}
.div8 {
  top: 276px;
}
.div10,
.div11,
.div12,
.div13,
.div14,
.div15,
.div9 {
  position: absolute;
  top: 305px;
  left: 11px;
  font-weight: 500;
}
.div10,
.div11,
.div12,
.div13,
.div14,
.div15 {
  top: 334px;
}
.div11,
.div12,
.div13,
.div14,
.div15 {
  top: 363px;
}
.div12,
.div13,
.div14,
.div15 {
  top: 392px;
}
.div13,
.div14,
.div15 {
  top: 421px;
}
.div14,
.div15 {
  top: 450px;
}
.div15 {
  top: 479px;
}
.div16,
.div17,
.div18,
.div19,
.div20,
.div21,
.div22 {
  position: absolute;
  top: 508px;
  left: 11px;
  font-weight: 500;
}
.div17,
.div18,
.div19,
.div20,
.div21,
.div22 {
  top: 537px;
}
.div18,
.div19,
.div20,
.div21,
.div22 {
  top: 566px;
}
.div19,
.div20,
.div21,
.div22 {
  top: 595px;
}
.div20,
.div21,
.div22 {
  top: 624px;
}
.div21,
.div22 {
  top: 653px;
}
.div22 {
  top: 682px;
}
.groupChild1,
.rectangleDiv {
  position: absolute;
  top: 34px;
  left: 71px;
  background-color: var(--color-red);
  border-top: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
  box-sizing: border-box;
  width: 100px;
  height: 33px;
}
.groupChild1 {
  top: 67px;
  border-top: 1px solid var(--color-mediumblue);
  border-right: 1px solid var(--color-mediumblue);
  border-left: 1px solid var(--color-mediumblue);
  height: 28px;
}
.groupChild2,
.groupChild3,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  position: absolute;
  top: 67px;
  left: 171px;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-dodgerblue-200);
  border-right: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
  box-sizing: border-box;
  width: 100px;
  height: 28px;
}
.groupChild3,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  left: 271px;
}
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  left: 371px;
}
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  left: 471px;
}
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  left: 571px;
}
.groupChild7,
.groupChild8,
.groupChild9 {
  left: 671px;
}
.groupChild8,
.groupChild9 {
  top: 95px;
  left: 71px;
  background-color: var(--color-red);
}
.groupChild9 {
  left: 171px;
  background-color: var(--color-white);
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  position: absolute;
  top: 95px;
  left: 271px;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-dodgerblue-200);
  border-right: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
  box-sizing: border-box;
  width: 100px;
  height: 28px;
}
.groupChild11,
.groupChild12,
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 371px;
}
.groupChild12,
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 471px;
}
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 571px;
}
.groupChild14,
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 671px;
}
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  top: 123px;
  left: 71px;
  background-color: var(--color-red);
}
.groupChild16,
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 171px;
  background-color: var(--color-white);
}
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 271px;
}
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 371px;
}
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 471px;
}
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 571px;
}
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 671px;
}
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  top: 151px;
  left: 71px;
  background-color: var(--color-red);
}
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 171px;
  background-color: var(--color-white);
}
.groupChild24,
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 271px;
}
.groupChild25,
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 371px;
}
.groupChild26,
.groupChild27,
.groupChild28 {
  left: 471px;
}
.groupChild27,
.groupChild28 {
  left: 571px;
}
.groupChild28 {
  left: 671px;
}
.groupChild29,
.groupChild30,
.groupChild31,
.groupChild32,
.groupChild33,
.groupChild34,
.groupChild35,
.groupChild36,
.groupChild37,
.groupChild38,
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  position: absolute;
  top: 179px;
  left: 71px;
  background-color: var(--color-red);
  border-top: 1px solid var(--color-dodgerblue-200);
  border-right: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
  box-sizing: border-box;
  width: 100px;
  height: 28px;
}
.groupChild30,
.groupChild31,
.groupChild32,
.groupChild33,
.groupChild34,
.groupChild35,
.groupChild36,
.groupChild37,
.groupChild38,
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 171px;
  background-color: var(--color-white);
}
.groupChild31,
.groupChild32,
.groupChild33,
.groupChild34,
.groupChild35,
.groupChild36,
.groupChild37,
.groupChild38,
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 271px;
}
.groupChild32,
.groupChild33,
.groupChild34,
.groupChild35,
.groupChild36,
.groupChild37,
.groupChild38,
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 371px;
}
.groupChild33,
.groupChild34,
.groupChild35,
.groupChild36,
.groupChild37,
.groupChild38,
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 471px;
}
.groupChild34,
.groupChild35,
.groupChild36,
.groupChild37,
.groupChild38,
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 571px;
}
.groupChild35,
.groupChild36,
.groupChild37,
.groupChild38,
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 671px;
}
.groupChild36,
.groupChild37,
.groupChild38,
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  top: 207px;
  left: 71px;
  background-color: var(--color-red);
}
.groupChild37,
.groupChild38,
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 171px;
  background-color: var(--color-white);
}
.groupChild38,
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 271px;
}
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 371px;
}
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 471px;
}
.groupChild41,
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 571px;
}
.groupChild42,
.groupChild43,
.groupChild44 {
  left: 671px;
}
.groupChild43,
.groupChild44 {
  top: 235px;
  left: 71px;
  background-color: var(--color-red);
}
.groupChild44 {
  left: 171px;
  background-color: var(--color-white);
}
.groupChild45,
.groupChild46,
.groupChild47,
.groupChild48,
.groupChild49,
.groupChild50,
.groupChild51,
.groupChild52,
.groupChild53,
.groupChild54,
.groupChild55,
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  position: absolute;
  top: 235px;
  left: 271px;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-dodgerblue-200);
  border-right: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
  box-sizing: border-box;
  width: 100px;
  height: 28px;
}
.groupChild46,
.groupChild47,
.groupChild48,
.groupChild49,
.groupChild50,
.groupChild51,
.groupChild52,
.groupChild53,
.groupChild54,
.groupChild55,
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 371px;
}
.groupChild47,
.groupChild48,
.groupChild49,
.groupChild50,
.groupChild51,
.groupChild52,
.groupChild53,
.groupChild54,
.groupChild55,
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 471px;
}
.groupChild48,
.groupChild49,
.groupChild50,
.groupChild51,
.groupChild52,
.groupChild53,
.groupChild54,
.groupChild55,
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 571px;
}
.groupChild49,
.groupChild50,
.groupChild51,
.groupChild52,
.groupChild53,
.groupChild54,
.groupChild55,
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 671px;
}
.groupChild50,
.groupChild51,
.groupChild52,
.groupChild53,
.groupChild54,
.groupChild55,
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  top: 263px;
  left: 71px;
  background-color: var(--color-red);
}
.groupChild51,
.groupChild52,
.groupChild53,
.groupChild54,
.groupChild55,
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 171px;
  background-color: var(--color-white);
}
.groupChild52,
.groupChild53,
.groupChild54,
.groupChild55,
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 271px;
}
.groupChild53,
.groupChild54,
.groupChild55,
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 371px;
}
.groupChild54,
.groupChild55,
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 471px;
}
.groupChild55,
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 571px;
}
.groupChild56,
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 671px;
}
.groupChild57,
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  top: 291px;
  left: 71px;
  height: 29px;
}
.groupChild58,
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 171px;
}
.groupChild59,
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 271px;
}
.groupChild60,
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 371px;
}
.groupChild61,
.groupChild62,
.groupChild63 {
  left: 471px;
}
.groupChild62,
.groupChild63 {
  left: 571px;
}
.groupChild63 {
  left: 671px;
}
.groupChild64,
.groupChild65,
.groupChild66,
.groupChild67,
.groupChild68,
.groupChild69,
.groupChild70,
.groupChild71,
.groupChild72,
.groupChild73,
.groupChild74,
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  position: absolute;
  top: 320px;
  left: 71px;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-dodgerblue-200);
  border-right: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
  box-sizing: border-box;
  width: 100px;
  height: 28px;
}
.groupChild65,
.groupChild66,
.groupChild67,
.groupChild68,
.groupChild69,
.groupChild70,
.groupChild71,
.groupChild72,
.groupChild73,
.groupChild74,
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 171px;
}
.groupChild66,
.groupChild67,
.groupChild68,
.groupChild69,
.groupChild70,
.groupChild71,
.groupChild72,
.groupChild73,
.groupChild74,
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 271px;
}
.groupChild67,
.groupChild68,
.groupChild69,
.groupChild70,
.groupChild71,
.groupChild72,
.groupChild73,
.groupChild74,
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 371px;
}
.groupChild68,
.groupChild69,
.groupChild70,
.groupChild71,
.groupChild72,
.groupChild73,
.groupChild74,
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 471px;
}
.groupChild69,
.groupChild70,
.groupChild71,
.groupChild72,
.groupChild73,
.groupChild74,
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 571px;
}
.groupChild70,
.groupChild71,
.groupChild72,
.groupChild73,
.groupChild74,
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 671px;
}
.groupChild71,
.groupChild72,
.groupChild73,
.groupChild74,
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  top: 348px;
  left: 71px;
}
.groupChild72,
.groupChild73,
.groupChild74,
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 171px;
}
.groupChild73,
.groupChild74,
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 271px;
}
.groupChild74,
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 371px;
}
.groupChild75,
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 471px;
}
.groupChild76,
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 571px;
}
.groupChild77,
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 671px;
}
.groupChild78,
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  top: 376px;
  left: 71px;
}
.groupChild79,
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 171px;
}
.groupChild80,
.groupChild81,
.groupChild82 {
  left: 271px;
}
.groupChild81,
.groupChild82 {
  left: 371px;
}
.groupChild82 {
  left: 471px;
}
.groupChild100,
.groupChild101,
.groupChild83,
.groupChild84,
.groupChild85,
.groupChild86,
.groupChild87,
.groupChild88,
.groupChild89,
.groupChild90,
.groupChild91,
.groupChild92,
.groupChild93,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  position: absolute;
  top: 376px;
  left: 571px;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-dodgerblue-200);
  border-right: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
  box-sizing: border-box;
  width: 100px;
  height: 28px;
}
.groupChild100,
.groupChild101,
.groupChild84,
.groupChild85,
.groupChild86,
.groupChild87,
.groupChild88,
.groupChild89,
.groupChild90,
.groupChild91,
.groupChild92,
.groupChild93,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 671px;
}
.groupChild100,
.groupChild101,
.groupChild85,
.groupChild86,
.groupChild87,
.groupChild88,
.groupChild89,
.groupChild90,
.groupChild91,
.groupChild92,
.groupChild93,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  top: 404px;
  left: 71px;
}
.groupChild100,
.groupChild101,
.groupChild86,
.groupChild87,
.groupChild88,
.groupChild89,
.groupChild90,
.groupChild91,
.groupChild92,
.groupChild93,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 171px;
}
.groupChild100,
.groupChild101,
.groupChild87,
.groupChild88,
.groupChild89,
.groupChild90,
.groupChild91,
.groupChild92,
.groupChild93,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 271px;
}
.groupChild100,
.groupChild101,
.groupChild88,
.groupChild89,
.groupChild90,
.groupChild91,
.groupChild92,
.groupChild93,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 371px;
}
.groupChild100,
.groupChild101,
.groupChild89,
.groupChild90,
.groupChild91,
.groupChild92,
.groupChild93,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 471px;
}
.groupChild100,
.groupChild101,
.groupChild90,
.groupChild91,
.groupChild92,
.groupChild93,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 571px;
}
.groupChild100,
.groupChild101,
.groupChild91,
.groupChild92,
.groupChild93,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 671px;
}
.groupChild100,
.groupChild101,
.groupChild92,
.groupChild93,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  top: 432px;
  left: 71px;
}
.groupChild100,
.groupChild101,
.groupChild93,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 171px;
}
.groupChild100,
.groupChild101,
.groupChild94,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 271px;
}
.groupChild100,
.groupChild101,
.groupChild95,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 371px;
}
.groupChild100,
.groupChild101,
.groupChild96,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 471px;
}
.groupChild100,
.groupChild101,
.groupChild97,
.groupChild98,
.groupChild99 {
  left: 571px;
}
.groupChild100,
.groupChild101,
.groupChild98,
.groupChild99 {
  left: 671px;
}
.groupChild100,
.groupChild101,
.groupChild99 {
  top: 460px;
  left: 71px;
}
.groupChild100,
.groupChild101 {
  left: 171px;
}
.groupChild101 {
  left: 271px;
}
.groupChild102,
.groupChild103,
.groupChild104,
.groupChild105,
.groupChild106,
.groupChild107,
.groupChild108,
.groupChild109,
.groupChild110,
.groupChild111,
.groupChild112,
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  position: absolute;
  top: 460px;
  left: 371px;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-dodgerblue-200);
  border-right: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
  box-sizing: border-box;
  width: 100px;
  height: 28px;
}
.groupChild103,
.groupChild104,
.groupChild105,
.groupChild106,
.groupChild107,
.groupChild108,
.groupChild109,
.groupChild110,
.groupChild111,
.groupChild112,
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 471px;
}
.groupChild104,
.groupChild105,
.groupChild106,
.groupChild107,
.groupChild108,
.groupChild109,
.groupChild110,
.groupChild111,
.groupChild112,
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 571px;
}
.groupChild105,
.groupChild106,
.groupChild107,
.groupChild108,
.groupChild109,
.groupChild110,
.groupChild111,
.groupChild112,
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 671px;
}
.groupChild106,
.groupChild107,
.groupChild108,
.groupChild109,
.groupChild110,
.groupChild111,
.groupChild112,
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  top: 488px;
  left: 71px;
}
.groupChild107,
.groupChild108,
.groupChild109,
.groupChild110,
.groupChild111,
.groupChild112,
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 171px;
}
.groupChild108,
.groupChild109,
.groupChild110,
.groupChild111,
.groupChild112,
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 271px;
}
.groupChild109,
.groupChild110,
.groupChild111,
.groupChild112,
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 371px;
}
.groupChild110,
.groupChild111,
.groupChild112,
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 471px;
}
.groupChild111,
.groupChild112,
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 571px;
}
.groupChild112,
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 671px;
}
.groupChild113,
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  top: 516px;
  left: 71px;
}
.groupChild114,
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 171px;
}
.groupChild115,
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 271px;
}
.groupChild116,
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 371px;
}
.groupChild117,
.groupChild118,
.groupChild119 {
  left: 471px;
}
.groupChild118,
.groupChild119 {
  left: 571px;
}
.groupChild119 {
  left: 671px;
}
.groupChild120,
.groupChild121,
.groupChild122,
.groupChild123,
.groupChild124,
.groupChild125,
.groupChild126,
.groupChild127,
.groupChild128,
.groupChild129,
.groupChild130,
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  position: absolute;
  top: 544px;
  left: 71px;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-dodgerblue-200);
  border-right: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
  box-sizing: border-box;
  width: 100px;
  height: 28px;
}
.groupChild121,
.groupChild122,
.groupChild123,
.groupChild124,
.groupChild125,
.groupChild126,
.groupChild127,
.groupChild128,
.groupChild129,
.groupChild130,
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 171px;
}
.groupChild122,
.groupChild123,
.groupChild124,
.groupChild125,
.groupChild126,
.groupChild127,
.groupChild128,
.groupChild129,
.groupChild130,
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 271px;
}
.groupChild123,
.groupChild124,
.groupChild125,
.groupChild126,
.groupChild127,
.groupChild128,
.groupChild129,
.groupChild130,
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 371px;
}
.groupChild124,
.groupChild125,
.groupChild126,
.groupChild127,
.groupChild128,
.groupChild129,
.groupChild130,
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 471px;
}
.groupChild125,
.groupChild126,
.groupChild127,
.groupChild128,
.groupChild129,
.groupChild130,
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 571px;
}
.groupChild126,
.groupChild127,
.groupChild128,
.groupChild129,
.groupChild130,
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 671px;
}
.groupChild127,
.groupChild128,
.groupChild129,
.groupChild130,
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  top: 572px;
  left: 71px;
}
.groupChild128,
.groupChild129,
.groupChild130,
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 171px;
}
.groupChild129,
.groupChild130,
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 271px;
}
.groupChild130,
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 371px;
}
.groupChild131,
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 471px;
}
.groupChild132,
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 571px;
}
.groupChild133,
.groupChild134,
.groupChild135,
.groupChild136 {
  left: 671px;
}
.groupChild134,
.groupChild135,
.groupChild136 {
  top: 600px;
  left: 71px;
}
.groupChild135,
.groupChild136 {
  left: 171px;
}
.groupChild136 {
  left: 271px;
}
.groupChild137,
.groupChild138,
.groupChild139,
.groupChild140,
.groupChild141,
.groupChild142,
.groupChild143,
.groupChild144,
.groupChild145,
.groupChild146,
.groupChild147,
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  position: absolute;
  top: 600px;
  left: 371px;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-dodgerblue-200);
  border-right: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
  box-sizing: border-box;
  width: 100px;
  height: 28px;
}
.groupChild138,
.groupChild139,
.groupChild140,
.groupChild141,
.groupChild142,
.groupChild143,
.groupChild144,
.groupChild145,
.groupChild146,
.groupChild147,
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 471px;
}
.groupChild139,
.groupChild140,
.groupChild141,
.groupChild142,
.groupChild143,
.groupChild144,
.groupChild145,
.groupChild146,
.groupChild147,
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 571px;
}
.groupChild140,
.groupChild141,
.groupChild142,
.groupChild143,
.groupChild144,
.groupChild145,
.groupChild146,
.groupChild147,
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 671px;
}
.groupChild141,
.groupChild142,
.groupChild143,
.groupChild144,
.groupChild145,
.groupChild146,
.groupChild147,
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  top: 625px;
  left: 71px;
}
.groupChild142,
.groupChild143,
.groupChild144,
.groupChild145,
.groupChild146,
.groupChild147,
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 171px;
}
.groupChild143,
.groupChild144,
.groupChild145,
.groupChild146,
.groupChild147,
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 271px;
}
.groupChild144,
.groupChild145,
.groupChild146,
.groupChild147,
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 371px;
}
.groupChild145,
.groupChild146,
.groupChild147,
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 471px;
}
.groupChild146,
.groupChild147,
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 571px;
}
.groupChild147,
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 671px;
}
.groupChild148,
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  top: 652px;
  left: 71px;
}
.groupChild149,
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 171px;
}
.groupChild150,
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 271px;
}
.groupChild151,
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 371px;
}
.groupChild152,
.groupChild153,
.groupChild154 {
  left: 471px;
}
.groupChild153,
.groupChild154 {
  left: 571px;
}
.groupChild154 {
  left: 671px;
}
.groupChild155,
.groupChild156 {
  top: 679px;
  border: 1px solid var(--color-dodgerblue-200);
  height: 28px;
}
.groupChild155 {
  position: absolute;
  left: 71px;
  background-color: var(--color-white);
  box-sizing: border-box;
  width: 100px;
}
.groupChild156 {
  left: 171px;
}
.groupChild156,
.groupChild157,
.groupChild158 {
  position: absolute;
  background-color: var(--color-white);
  box-sizing: border-box;
  width: 100px;
}
.groupChild157 {
  top: 679px;
  left: 271px;
  border: 1px solid var(--color-dodgerblue-200);
  height: 28px;
}
.groupChild158 {
  left: 371px;
}
.groupChild158,
.groupChild159,
.groupChild160 {
  top: 679px;
  border: 1px solid var(--color-dodgerblue-200);
  height: 28px;
}
.groupChild159 {
  position: absolute;
  left: 471px;
  background-color: var(--color-white);
  box-sizing: border-box;
  width: 100px;
}
.groupChild160 {
  left: 571px;
}
.groupChild160,
.groupChild161,
.groupChild162,
.groupChild163 {
  position: absolute;
  background-color: var(--color-white);
  box-sizing: border-box;
  width: 100px;
}
.groupChild161 {
  top: 679px;
  left: 671px;
  border: 1px solid var(--color-dodgerblue-200);
  height: 28px;
}
.groupChild162,
.groupChild163 {
  top: 34px;
  height: 33px;
}
.groupChild162 {
  left: 171px;
  border-top: 1px solid var(--color-dodgerblue-200);
  border-bottom: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
}
.groupChild163 {
  left: 271px;
}
.groupChild163,
.groupChild164,
.groupChild165 {
  border-top: 1px solid var(--color-dodgerblue-200);
  border-bottom: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
}
.groupChild164 {
  position: absolute;
  top: 34px;
  left: 371px;
  background-color: var(--color-white);
  box-sizing: border-box;
  width: 99px;
  height: 33px;
}
.groupChild165 {
  left: 470px;
}
.groupChild165,
.groupChild166,
.groupChild167 {
  position: absolute;
  top: 34px;
  background-color: var(--color-white);
  box-sizing: border-box;
  width: 100px;
  height: 33px;
}
.groupChild166 {
  left: 570px;
  border-top: 1px solid var(--color-dodgerblue-200);
  border-bottom: 1px solid var(--color-dodgerblue-200);
  border-left: 1px solid var(--color-dodgerblue-200);
}
.groupChild167 {
  left: 670px;
  border: 1px solid var(--color-dodgerblue-200);
}
.rectangleParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 824px;
  height: 780px;
}
.groupParent {
  position: relative;
  width: 100%;
  height: 780px;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-black);
  font-family: var(--font-inter);
}
