.frameChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-sm);
  background-color: var(--color-whitesmoke-100);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 1039px;
  height: 637px;
}
.frameInner,
.frameItem {
  position: absolute;
  top: 39.5px;
  left: 22.5px;
  border-radius: var(--br-lg);
  width: 31px;
  height: 31px;
}
.frameInner {
  top: 47.5px;
  left: 29.5px;
  width: 16px;
  height: 16px;
}
.uncontrolledCharging {
  position: absolute;
  top: 45.5px;
  left: 77px;
  font-weight: 600;
}
.vectorIcon {
  position: absolute;
  top: 118.5px;
  left: 22.5px;
  border-radius: var(--br-lg);
  width: 31px;
  height: 31px;
}
.uncontrolledCharging1 {
  position: absolute;
  top: 124.5px;
  left: 77px;
  font-weight: 600;
}
.frameChild1 {
  position: absolute;
  top: 197.5px;
  left: 22.5px;
  border-radius: var(--br-lg);
  width: 31px;
  height: 31px;
}
.managedCharging {
  position: absolute;
  top: 203.5px;
  left: 77px;
  font-weight: 600;
}
.frameChild2,
.frameChild3 {
  position: absolute;
  top: 276.5px;
  left: 22.5px;
  border-radius: var(--br-lg);
  width: 31px;
  height: 31px;
}
.frameChild3 {
  top: 284.5px;
  left: 29.5px;
  width: 16px;
  height: 16px;
}
.managedCharging1 {
  position: absolute;
  top: 282.5px;
  left: 77px;
}
.frameChild4,
.frameChild5 {
  position: absolute;
  top: 355.5px;
  left: 22.5px;
  border-radius: var(--br-lg);
  width: 31px;
  height: 31px;
}
.frameChild5 {
  top: 363.5px;
  left: 29.5px;
  width: 16px;
  height: 16px;
}
.tariffOptimizedCharging {
  position: absolute;
  top: 361.5px;
  left: 77px;
}
.frameChild6,
.frameChild7 {
  position: absolute;
  top: 434.5px;
  left: 22.5px;
  border-radius: var(--br-lg);
  width: 31px;
  height: 31px;
}
.frameChild7 {
  top: 442.5px;
  left: 29.5px;
  width: 16px;
  height: 16px;
}
.tariffAndFeeder {
  position: absolute;
  top: 440.5px;
  left: 77px;
}
.frameChild8,
.frameChild9 {
  position: absolute;
  top: 513.5px;
  left: 22.5px;
  border-radius: var(--br-lg);
  width: 31px;
  height: 31px;
}
.frameChild9 {
  top: 521.5px;
  left: 29.5px;
  width: 16px;
  height: 16px;
}
.derIntegration {
  position: absolute;
  top: 519.5px;
  left: 77px;
}
.frameChild10,
.frameChild11 {
  position: absolute;
  top: 592.5px;
  left: 20.5px;
  border-radius: var(--br-lg);
  width: 31px;
  height: 31px;
}
.frameChild11 {
  top: 600.5px;
  left: 27.5px;
  width: 16px;
  height: 16px;
}
.rectangleDiv,
.smartCharging {
  position: absolute;
  top: 598.5px;
  left: 75px;
}
.rectangleDiv {
  top: 19px;
  left: 498px;
  border-radius: var(--br-sm);
  background-color: var(--color-whitesmoke-100);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 541px;
  height: 618px;
}
.connectedPower {
  font-weight: 600;
}
.connectedPowerKwContainer,
.sanctionedPowerKwContainer {
  position: absolute;
  top: 68px;
  left: 530px;
  color: var(--color-darkslategray);
}
.sanctionedPowerKwContainer {
  top: 123px;
}
.frameChild12,
.frameChild13 {
  position: absolute;
  top: 207px;
  left: 515px;
  border-radius: var(--br-md);
  background-color: var(--color-gainsboro);
  width: 509px;
  height: 411px;
}
.frameChild13 {
  top: 265px;
  background-color: var(--color-gray);
  height: 51px;
}
.name {
  position: absolute;
  top: 279.5px;
  left: 544px;
  font-weight: 600;
  color: var(--color-white);
}
.noOf {
  margin-block-start: 0;
  margin-block-end: 0;
}
.chargers {
  margin: 0;
}
.noOfChargersContainer {
  position: absolute;
  top: 270px;
  left: 628.67px;
  font-weight: 600;
  color: var(--color-white);
  text-align: center;
}
.chargingBays,
.connectedPower1,
.peakLimit {
  position: absolute;
  top: 279.5px;
  left: 737.33px;
  font-weight: 600;
  color: var(--color-white);
}
.chargingBays,
.peakLimit {
  left: 915px;
}
.chargingBays {
  top: 226px;
  left: 536px;
  color: var(--color-darkslategray);
}
.rectangleParent {
  position: relative;
  width: 100%;
  height: 637px;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-inter);
}
