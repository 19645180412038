.moving-border  {
    height: 70px; /* Change height to control the overall height */
    width: 5px;   /* Change width to control the overall width */
    background-image: repeating-linear-gradient(to top, 
        transparent, transparent 2px, #008001 7px);
    background-size: 100% 200%;
    animation: change 3s linear infinite;
  }

  .border {
    border: 2px dashed black;
    height: 70px;
  }

  .border-dark {
    border: 2px dashed #F2E34C; /* Yellow color for dark theme */
    height: 70px;
  }
  
  @keyframes change {
    from {
      background-position: bottom; /* Start from top */
    }
    to {
      background-position: top; /* End at bottom */
    }
  }