#container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

#zoomed-area {
  width: 100%;
  height: 100%;
  transform-origin: top left;
  transition: transform 0.5s ease;
  position: absolute;
}